import {
    FETCH_SUBSCRIPTION_PLANS_SUCCESS,
    FETCH_CURRENT_USER_PLAN_SUCCESS,
    FETCH_SUBSCRIPTION_INFO,
} from 'state/payments/payments.actions';
import { Plan } from './types';
import { SubscriptionInfoJson } from 'services/api/index.contracts';

type InitialState = {
    plans: Plan[];
    currentPlan: any;
    info?: SubscriptionInfoJson;
};

const initialState: InitialState = {
    plans: [],
    currentPlan: null,
};
export default function paymentsReducer(state = initialState, action): InitialState {
    switch (action.type) {
        case FETCH_SUBSCRIPTION_PLANS_SUCCESS:
            return {
                ...state,
                plans: action.payload,
            };
        case FETCH_SUBSCRIPTION_INFO:
            return {
                ...state,
                info: action.payload,
            };
        case FETCH_CURRENT_USER_PLAN_SUCCESS:
            if (!action.payload) {
                return {
                    ...state,
                    currentPlan: null,
                };
            }

            const { plan } = action.payload;

            let monthlyAmount = plan.amount;

            if (plan.nickname === 'semi-annual') {
                monthlyAmount = plan.amount / 6;
            } else if (plan.nickname === 'annual') {
                monthlyAmount = plan.amount / 12;
            }

            return {
                ...state,
                currentPlan: {
                    ...action.payload,
                    plan: {
                        ...action.payload.plan,
                        monthlyAmount,
                    },
                },
            };
        default:
            return state;
    }
}
