import loadImg from 'assets/images/ripple-loader.svg';
import styles from './PageLoading.module.scss';

function PageLoading() {
    return (
        <div className={styles.wrapper}>
            <img src={loadImg} alt="" />
        </div>
    );
}

export default PageLoading;
