import { SUGGEST_NEW_TAG, LOAD_TAGS } from './actions';

type Tag = {
    name: string
    type: 'thought' | 'topic'
}

const initialState: Tag[] = [];

export default function tagsReducer(state = initialState, action): Tag[] {
    switch (action.type) {
        case LOAD_TAGS: {
            return action.payload;
        }
        case SUGGEST_NEW_TAG:
            return [
                ...state,
                { ...action.payload }
            ];
        default:
            return state;
    }
}