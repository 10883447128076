import { Api } from 'services/api';
import { store } from 'state/store';

export const SET_MOVIE_FOR_ACTION = 'SET_MOVIE_FOR_ACTION';
export const GET_MOVIE_LIST = 'GET_MOVIE_LIST';
export const ADD_MOVIE_TO_FAVORITES = 'ADD_MOVIE_TO_FAVORITES';
export const DELETE_FROM_FAVORITES = 'DELETE_FROM_FAVORITES';
export const DELETE_FROM_HIDDEN = 'DELETE_FROM_HIDDEN';
export const CHECK_MOVIE_EXISTS = 'CHECK_MOVIE_EXISTS';

export const getMovieList = (type: 'favorite' | 'hidden', expand = false) => {
    return Api.movies.getMovieList(type, expand).then(
        response => {
            store.dispatch({
                type: GET_MOVIE_LIST,
                payload: {
                    type,
                    list: response.data.movies,
                },
            });
            return Promise.resolve(response);
        },
        err => Promise.reject(err),
    );
};

export const getFavoritesAsSessions = async () => {
    const res = await Api.movies.getFavoritesAsSessions();
    return res.data;
};

export const setMovieIdForAction = (id: string) => {
    store.dispatch({
        type: SET_MOVIE_FOR_ACTION,
        payload: id,
    });
};

export const addMovieToFavorites = async (id: string) => {
    try {
        await Api.movies.addToFavorites(id);

        store.dispatch(setMovieIdForAction(null));
    } catch (error) {
        Promise.reject(error);
    }
};

export const deleteFromFavorites = (id: string) => {
    return Api.movies.deleteFromFavorites(id);
};

export const deleteFromHidden = async (movieId: string) => {
    await Api.movies.deleteFromHidden(movieId);
    store.dispatch({
        type: DELETE_FROM_HIDDEN,
        payload: movieId,
    });
};

export const checkMovieExists = async (id: string, type: 'favorite' | 'hidden') => {
    const response = await Api.movies.checkMovieExists(id, type);
    return response;
};
