import {
    SEARCH_MOVIES,
    CLEAR_SEARCH_MOVIES,
    GET_GOAL_SESSIONS_REQUEST,
    SET_SESSION_FOR_ACTION,
    FETCH_SESSION_DETAILS,
    GET_GOAL_SESSIONS,
} from 'state/actions/session.actions';
import type { MovieJson, SessionJson } from 'services/api/index.contracts';

type SessionDetails = Record<string, SessionJson>;

export type SessionState = {
    movies: MovieJson[];
    byGoalSessions: {
        list: any[];
        loading: boolean;
    };
    sessionForAction: SessionJson;
    sessionCount: any;
    sessionDetails: SessionDetails;
};

const initialState: SessionState = {
    movies: [],
    byGoalSessions: {
        list: [],
        loading: false,
    },
    sessionForAction: null,
    sessionCount: {},
    sessionDetails: {},
};

function sessionReducer(state = initialState, action): typeof initialState {
    switch (action.type) {
        case SEARCH_MOVIES:
            return {
                ...state,
                movies: action.payload || [],
            };
        case CLEAR_SEARCH_MOVIES:
            return {
                ...state,
                movies: [],
            };
        case GET_GOAL_SESSIONS_REQUEST:
            return {
                ...state,
                byGoalSessions: {
                    ...state.byGoalSessions,
                    loading: true,
                },
            };
        case GET_GOAL_SESSIONS:
            return {
                ...state,
                byGoalSessions: {
                    ...state.byGoalSessions,
                    list: action.payload,
                    loading: false,
                },
            };
        case SET_SESSION_FOR_ACTION:
            return {
                ...state,
                sessionForAction: action.payload,
            };
        case FETCH_SESSION_DETAILS:
            return {
                ...state,
                sessionDetails: {
                    ...state.sessionDetails,
                    [action.payload._id]: action.payload,
                },
            };
        default:
            return state;
    }
}

export default sessionReducer;
