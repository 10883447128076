export const ADD_NEW_NOTIFICATION = 'ADD_NEW_NOTIFICATION';
export const REMOVE_CURRENT_NOTIFICATION = 'REMOVE_CURRENT_NOTIFICATION';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';

export const addNotification = notification => {
    return {
        type: ADD_NEW_NOTIFICATION,
        payload: notification,
    };
};

export const removeCurrentNotification = () => {
    return {
        type: REMOVE_CURRENT_NOTIFICATION,
    };
};
