import { Api } from 'services/api';
import { GoogleSignInResponse } from 'services/google/init';
import { store } from 'state/store';

export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_REVOKE = 'AUTHENTICATION_REVOKE';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';
export const CLEAR_SIGNUP_ERROR = 'CLEAR_SIGNUP_ERROR';

export const saveAuthData = token => {
    localStorage.setItem('token', token);
    Api.setAuthData(token);
};

const revokeAuthData = () => {
    Api.revokeAuthData();
    localStorage.removeItem('token');
};

export const userIsAuthenticated = () => {
    const token = localStorage.getItem('token');

    return !!token;
};

export const logout = () => {
    revokeAuthData();
    localStorage.clear();
    store.dispatch({
        type: USER_LOGOUT,
    });
};

export function signUp(data) {
    return Api.auth.signup(data).then(
        response => {
            saveAuthData(response.data.token);

            store.dispatch({
                type: SIGNUP_SUCCESS,
            });

            return response;
        },
        error => {
            store.dispatch({
                type: SIGNUP_ERROR,
                payload: error,
            });

            return Promise.reject(error);
        },
    );
}

export function login(data) {
    return Api.auth.login(data).then(
        response => {
            saveAuthData(response.data.token);
            store.dispatch({
                type: LOGIN_SUCCESS,
            });

            return response;
        },
        error => {
            store.dispatch({
                type: LOGIN_ERROR,
                payload: error,
            });

            return Promise.reject(error);
        },
    );
}

export const clearLoginError = () => {
    store.dispatch({
        type: CLEAR_LOGIN_ERROR,
    });
};

export const clearSignupError = () => {
    store.dispatch({
        type: CLEAR_SIGNUP_ERROR,
    });
};

export function authenticate(password) {
    return Api.auth.authenticate(password).then(response => {
        store.dispatch({
            type: AUTHENTICATION_SUCCESS,
        });

        return response.data;
    });
}

export function revokeIdentityConfirmation() {
    store.dispatch({
        type: AUTHENTICATION_REVOKE,
    });
}

function facebookLoginHelper(): Promise<string> {
    return new Promise((resolve, reject) => {
        try {
            window.FB.login(
                function (response: any) {
                    if (response.authResponse) {
                        resolve(response.authResponse.accessToken);
                    } else {
                        reject(response);
                    }
                },
                { scope: 'public_profile,email' },
            );
        } catch (error) {
            reject(error);
        }
    });
}

export async function loginWithFacebook() {
    try {
        const accessToken = await facebookLoginHelper();

        const response = await Api.auth.facebook.login(accessToken);

        saveAuthData(response.data.token);

        store.dispatch({
            type: LOGIN_SUCCESS,
        });

        return response;
    } catch (error) {
        store.dispatch({
            type: LOGIN_ERROR,
            payload: error,
        });

        return Promise.reject(error);
    }
}

export async function loginWithGoogle(identity: GoogleSignInResponse) {
    try {
        const response = await Api.auth.google.login(identity);
        saveAuthData(response.data.token);

        store.dispatch({
            type: LOGIN_SUCCESS,
        });

        return response;
    } catch (error) {
        store.dispatch({
            type: LOGIN_ERROR,
            payload: error,
        });
    }
}
