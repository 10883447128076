import { Api } from 'services/api';
import { store } from 'state/store';

export const CAHM_QUESTIONAIRE_SUCCESS = 'CAHM_QUESTIONAIRE_SUCCESS';

export const getQuestionaire = () => {
    Api.cahm.questionaire.get().then(response => {
        store.dispatch({
            type: CAHM_QUESTIONAIRE_SUCCESS,
            payload: response.data,
        });
    }, console.error);
};

export const sendAnswers = async data => {
    const response = await Api.cahm.questionaire.answers.create(data);
    return response.data;
};
