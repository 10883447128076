import {
    MAILING_LIST_SUBSCRIBE_ERROR,
    MAILING_LIST_SUBSCRIBE_REQUEST,
    MAILING_LIST_SUBSCRIBE_SUCCESS,
    MAILING_PREFERENCES_SUCCESS,
} from './actions';

type MailingListState = {
    preferences: {
        _id: string;
        name: string;
        value: boolean;
    }[];
    subscription: {
        status: 'idle' | 'loading' | 'success' | 'error';
        errorMessage?: string;
    };
};

const initialState: MailingListState = {
    preferences: null,
    subscription: {
        status: 'idle',
        errorMessage: undefined,
    },
};

export const mailingListReducer = (state = initialState, action): MailingListState => {
    switch (action.type) {
        case MAILING_PREFERENCES_SUCCESS:
            return {
                ...state,
                preferences: action?.payload ? action.payload.notifications : [],
            };

        case MAILING_LIST_SUBSCRIBE_REQUEST:
            return {
                ...state,
                subscription: {
                    ...state.subscription,
                    status: 'loading',
                },
            };
        case MAILING_LIST_SUBSCRIBE_SUCCESS:
            return {
                ...state,
                subscription: {
                    ...state.subscription,
                    status: 'success',
                },
            };

        case MAILING_LIST_SUBSCRIBE_ERROR:
            return {
                ...state,
                subscription: {
                    status: 'error',
                    errorMessage: action?.meta?.error?.message,
                },
            };

        default:
            return state;
    }
};
