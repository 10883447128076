import { FETCH_ONBOARDING_DATA_SUCCESS } from 'state/actions/onboarding.actions';

type OnboardingState = {
    goal: boolean;
    tutorial: boolean;
    welcome: boolean;
};

const initialState: OnboardingState = {
    goal: false,
    tutorial: false,
    welcome: false,
};

function onBoardingReducer(state = initialState, action): OnboardingState {
    switch (action.type) {
        case FETCH_ONBOARDING_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

export default onBoardingReducer;
