import { Api } from 'services/api';
import { store } from 'state/store';
export const FETCH_GOALS_SUCCESS = 'FETCH_GOALS_SUCCESS';
export const FETCH_GOAL_LEVEL_INFO_REQUEST = 'FETCH_GOAL_LEVEL_INFO_REQUEST';
export const FETCH_GOAL_LEVEL_INFO_SUCCESS = 'FETCH_GOAL_LEVEL_INFO_SUCCESS';
export const FETCH_PROGRAM_UPDATES_INFO_SUCCESS = 'FETCH_PROGRAM_UPDATES_INFO_SUCCESS';
export const FETCH_GOAL_LEVELS_SUCCESS = 'FETCH_GOAL_LEVELS_SUCCESS';
export const GOAL_LEVEL_INFO_RESET = 'GOAL_LEVEL_INFO_RESET';
export const UPDATE_PROGRAM_OFFER_REJECT = 'UPDATE_PROGRAM_OFFER_REJECT';
export const CLEAR_UPDATE_PROGRAM_OFFER_REJECT = 'CLEAR_UPDATE_PROGRAM_OFFER_REJECT';
export const LEVELUP_OFFER_REJECT = 'LEVELUP_OFFER_REJECT';
export const CLEAR_LEVELUP_OFFER_REJECT = 'CLEAR_LEVELUP_OFFER_REJECT';
export const FETCH_GOAL_PROGRESS_SUCCESS = 'FETCH_GOAL_PROGRESS_SUCCESS';
export const FETCH_NEXT_GOAL_SUCCESS = 'FETCH_NEXT_GOAL_SUCCESS';

export function fetchGoalsList() {
    return Api.goals.get().then(response => {
        store.dispatch({
            type: FETCH_GOALS_SUCCESS,
            goals: response.data.goals,
        });

        return response.data.goals;
    });
}

export function sendUserGoals(data) {
    return Api.goals.setDefault(data);
}

export const getGoalProgress = async () => {
    const result = await Api.goals.getProgress();
    store.dispatch({
        type: FETCH_GOAL_PROGRESS_SUCCESS,
        progress: result.data,
    });
};

export const setGoal = (goalId: string) => {
    return Api.goals.update(goalId);
};

export const getNextGoal = async () => {
    const goal = await Api.goals.getNextGoal();
    store.dispatch({
        type: FETCH_NEXT_GOAL_SUCCESS,
        goal: goal.data,
    });
    return goal;
};

export const completeGoal = () => {
    return Api.goals.complete();
};

export const getLevelInfo = () => {
    store.dispatch({
        type: FETCH_GOAL_LEVEL_INFO_REQUEST,
    });
    return Api.goals.levels
        .info()
        .then(response => {
            store.dispatch({
                type: FETCH_GOAL_LEVEL_INFO_SUCCESS,
                levelInfo: response.data,
            });
            return response;
        })
        .catch(error => console.error({ getLevelInfoError: error }));
};

export const checkProgramUpdates = () => {
    return Api.goals
        .checkForProgramUpdates()
        .then(response => {
            store.dispatch({
                type: FETCH_PROGRAM_UPDATES_INFO_SUCCESS,
                programUpdateInfo: response.data,
            });
            return response;
        })
        .catch(error => console.error({ getLevelInfoError: error }));
};

export const clearProgramUpdateOfferRejection = () => {
    localStorage.removeItem('program-update.offer.rejected');

    store.dispatch({
        type: CLEAR_UPDATE_PROGRAM_OFFER_REJECT,
    });
};

export const rejectProgramUpdateOffer = () => {
    localStorage.setItem('program-update.offer.rejected', 'true');

    store.dispatch({
        type: UPDATE_PROGRAM_OFFER_REJECT,
    });
};

export const rejectLevelUpOffer = (name: string, level: number) => {
    localStorage.setItem(`levelup.offer.rejected.${name}-${level}`, 'true');

    store.dispatch({
        type: LEVELUP_OFFER_REJECT,
    });
};

export const clearLevelUpOfferRejection = (name: string, level: number) => {
    localStorage.removeItem(`levelup.offer.rejected.${name}-${level}`);

    store.dispatch({
        type: CLEAR_LEVELUP_OFFER_REJECT,
    });
};

export const levelUp = () => {
    return Api.goals.levels.levelup();
};

export const clearLevelInfo = () => {
    store.dispatch({
        type: GOAL_LEVEL_INFO_RESET,
    });
};
