import { Api } from 'services/api';
import { store } from 'state/store';

export const FETCH_USER_INFO = 'FETCH_USER_INFO';
export const USER_LOGOUT = 'USER_LOGOUT';

export async function getUserData() {
    const response = await Api.users.me();
    store.dispatch({
        type: FETCH_USER_INFO,
        payload: response.data,
    });

    return await Promise.resolve(response);
}

export function updateUserData(data) {
    return Api.users.info.update(data).then(
        response => {
            if (response.data.name) {
                store.dispatch({
                    type: 'USER_CHANGE_NAME',
                    payload: response.data.name,
                });
            }

            if (response.data.email) {
                store.dispatch({
                    type: 'USER_SET_PENDING_EMAIL',
                    payload: response.data.email,
                });
            }

            return response.data;
        },
        err => Promise.reject(err),
    );
}

export function updateUserAvatar(data) {
    return Api.users.avatar.update(data).then(
        response => {
            getUserData();
            return Promise.resolve(response);
        },
        err => Promise.reject(err),
    );
}

export const changePassword = (oldPassword: string, newPassword: string) => {
    return Api.users.password.change(oldPassword, newPassword);
};
