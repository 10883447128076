import { createTheme } from '@material-ui/core/styles';

export const materialTheme = createTheme({
    palette: {
        primary: {
            main: '#f9957e',
            light: '#757ce8',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            main: '#fb957e',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        htmlFontSize: 16,
        fontSize: 16,
    },
});
