import { memo, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Api } from 'services/api';

export default memo(() => {
    const [confirmed, setConfirmed] = useState(false);
    const history = useHistory();
    const params = useParams<{ code: string }>();
    const [error, setError] = useState('');

    useEffect(() => {
        const confirmCode = async code => {
            try {
                await Api.users.email.confirm(code);
                setConfirmed(true);
            } catch (error) {
                if (error.type === 'EMAIL_ALREADY_CONFIRMED') {
                    setError('Email already confirmed');
                }
                console.error(error);
            }
        };

        if (params.code) {
            confirmCode(params.code);
        } else {
            history.push('/');
        }
    }, [history, params]);

    return (
        <div className="main">
            <div className="container">
                <div className="EmailConfirm">
                    {confirmed && (
                        <div>
                            <p>Thank you!</p>
                            <p>Your email has been confirmed</p>
                            <Link to="/" className="button button--black EmailConfirm__button">
                                Continue
                            </Link>
                        </div>
                    )}
                    {!confirmed && !error && (
                        <div>
                            <p>Loading...</p>
                        </div>
                    )}
                    {error}
                </div>
            </div>
        </div>
    );
});