const configs = {
    features: {
        billing: {
            enabled: false
        }
    },
    stripe: {
        apiKey: 'pk_test_FipCti4dgi7i56UwgoJUwkp600rG3rwQOt',
    },
    goals: {
        totalCount: 6
    },
    ws: {
        baseUrl: '',
        socketPath: '/socket/',
    },
};

export default configs;
