import type { ContentCardJson, MovieJson, SessionJson } from 'services/api/index.contracts';
import {
    FETCH_ACTIVITY_BY_DATE_RANGE,
    FETCH_CONTENT_CARDS_SUCCESS,
    FETCH_CURRENT_ACTIVITY_SUCCESS,
    RESET_CURRENT_ACTIVITIES,
    SET_CONTENT_LOADING,
    SET_CURRENT_ACTIVITY_LOADING,
    SKIP_SESSION,
} from 'state/actions/user-activity.actions';

type UserActivityState = {
    items: SessionJson[];
    movies: MovieJson[];
    contentCards: ContentCardJson[];
    activitiesByDateRange: any;
    activitiesLoading: boolean;
    contentLoading: boolean;
};

const initialState: UserActivityState = {
    items: [],
    movies: [],
    contentCards: [],
    activitiesByDateRange: null,
    activitiesLoading: false,
    contentLoading: true,
};

function userActivityReducer(state = initialState, action): typeof initialState {
    switch (action.type) {
        case FETCH_CURRENT_ACTIVITY_SUCCESS:
            return {
                ...state,
                items: action.payload.sessions,
                movies: action.payload.movies || [],
                contentLoading: false,
                activitiesLoading: false,
            };
        case SKIP_SESSION:
            return {
                ...state,
                items: state.items.filter(it => it._id !== action.payload),
            };
        case FETCH_CONTENT_CARDS_SUCCESS:
            return {
                ...state,
                contentCards: action.payload,
            };
        case RESET_CURRENT_ACTIVITIES:
            return {
                ...state,
                items: [],
            };
        case FETCH_ACTIVITY_BY_DATE_RANGE:
            return {
                ...state,
                activitiesByDateRange: action.payload,
            };
        case SET_CURRENT_ACTIVITY_LOADING:
            return {
                ...state,
                activitiesLoading: action.payload,
            };
        case SET_CONTENT_LOADING:
            return {
                ...state,
                contentLoading: action.payload,
            };
        default:
            return state;
    }
}

export default userActivityReducer;
