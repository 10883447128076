import { Api } from 'services/api';
import { store } from 'state/store';

export const FETCH_ONBOARDING_DATA_SUCCESS = 'FETCH_ONBOARDING_DATA_SUCCESS';

export const getOnboardingData = () => {
    return Api.users.onboarding.get().then(response => {
        store.dispatch({
            type: FETCH_ONBOARDING_DATA_SUCCESS,
            payload: response.data,
        });
    });
};

export const updateOnboardingData = (name: string) => {
    return Api.users.onboarding.update(name).then(response => {
        store.dispatch({
            type: FETCH_ONBOARDING_DATA_SUCCESS,
            payload: response.data,
        });

        return response;
    });
};
