import { Api } from 'services/api';
import { store } from 'state/store';

export const MAILING_PREFERENCES_SUCCESS = 'MAILING_PREFERENCES_SUCCESS';
export const MAILING_LIST_SUBSCRIBE_SUCCESS = 'MAILING_LIST_SUBSCRIBE_SUCCESS';
export const MAILING_LIST_SUBSCRIBE_REQUEST = 'MAILING_LIST_SUBSCRIBE_REQUEST';
export const MAILING_LIST_SUBSCRIBE_ERROR = 'MAILING_LIST_SUBSCRIBE_ERROR';

export const getPreferences = () => {
    return Api.mailingList.settings.get().then(response => {
        store.dispatch({
            type: MAILING_PREFERENCES_SUCCESS,
            payload: response.data.settings,
        });
    });
};

export const subscribeForNewsletter = email => {
    store.dispatch({
        type: MAILING_LIST_SUBSCRIBE_REQUEST,
    });

    Api.mailingList.subscribe(email).then(
        () => {
            store.dispatch({
                type: MAILING_LIST_SUBSCRIBE_SUCCESS,
            });
        },
        error => {
            store.dispatch({
                type: MAILING_LIST_SUBSCRIBE_ERROR,
                meta: {
                    error: error,
                },
            });
        },
    );
};
