import { SET_MOVIE_FOR_ACTION, GET_MOVIE_LIST } from 'state/actions/movies.actions';

type State = {
    movieForAction?: string;
    favorite: any[];
};

const initialState: State = {
    movieForAction: null,
    favorite: [],
};

function moviesReducer(state: State = initialState, action): State {
    switch (action.type) {
        case GET_MOVIE_LIST:
            // here we set hidden or favorite list
            if (action?.payload?.type) {
                return {
                    ...state,
                    [action.payload.type]: action.payload.list,
                };
            }
            return state;
        case SET_MOVIE_FOR_ACTION:
            return {
                ...state,
                movieForAction: action.payload,
            };
        default:
            return state;
    }
}

export default moviesReducer;
