import thunk from 'redux-thunk';
import reducers from 'state/reducers';
import { createStore, applyMiddleware, compose } from 'redux';

let composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
if (process.env.NODE_ENV === 'production') {
    composeEnhancer = compose;
}

export type RootState = ReturnType<typeof reducers>;

export const store = createStore(reducers, composeEnhancer(applyMiddleware(thunk)));

export const getState: () => RootState = store.getState;

if (process.env.NODE_ENV === 'development') {
    window.store = store.getState;
}
