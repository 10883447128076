import { Api } from 'services/api';
import { store } from 'state/store';

export const FETCH_IPI_USER_STATS_SUCCESS = 'FETCH_IPI_USER_STATS_SUCCESS';
export const FETCH_IPI_GAIN_INSIGHT_STATS_SUCCESS = 'FETCH_IPI_GAIN_INSIGHT_STATS_SUCCESS';
export const FETCH_IPI_GAIN_INSIGHTS_BY_EMOTION = 'FETCH_IPI_GAIN_INSIGHTS_BY_EMOTION';
export const ADD_IPI_FETCHED_GAIN_INSIGHTS_BY_EMOTIONS = 'ADD_IPI_FETCHED_GAIN_INSIGHTS_BY_EMOTIONS';
export const FETCH_IPI_STATS_COUNT_SUCCESS = 'FETCH_IPI_STATS_COUNT_SUCCESS';

export const getUserStats = () => {
    return Api.ipi.stats.get().then(response => {
        store.dispatch({
            type: FETCH_IPI_USER_STATS_SUCCESS,
            payload: response.data,
        });
    });
};

export const getStatsCount = () => {
    return Api.ipi.stats.count().then(response => {
        store.dispatch({
            type: FETCH_IPI_STATS_COUNT_SUCCESS,
            payload: response.data,
        });
    });
};

export const getGainInsightStats = () => {
    return Api.ipi.stats.gainInsight.get().then(response => {
        store.dispatch({
            type: FETCH_IPI_GAIN_INSIGHT_STATS_SUCCESS,
            payload: response.data,
        });
    });
};

export const addGainInsightSplitByEmotions = (emotionName: string) => {
    return Api.ipi.stats.gainInsight.getByEmotionName(emotionName).then(
        response => {
            store.dispatch({
                type: ADD_IPI_FETCHED_GAIN_INSIGHTS_BY_EMOTIONS,
                payload: {
                    emotionName,
                    list: response.data.list,
                },
            });
            return Promise.resolve(response);
        },
        error => Promise.reject(error),
    );
};
