import { Api } from 'services/api';
import { getState, store } from 'state/store';

export const FETCH_CURRENT_ACTIVITY_SUCCESS = 'FETCH_CURRENT_ACTIVITY_SUCCESS';
export const FETCH_CONTENT_CARDS_SUCCESS = 'FETCH_CONTENT_CARDS_SUCCESS';
export const RESET_CURRENT_ACTIVITIES = 'RESET_CURRENT_ACTIVITIES';
export const COMPLETE_USER_ACTIVITY_MOVIE_SUCCESS = 'COMPLETE_USER_ACTIVITY_MOVIE_SUCCESS';
export const FETCH_ACTIVITY_BY_DATE_RANGE = 'FETCH_ACTIVITY_BY_DATE_RANGE';
export const SET_CURRENT_ACTIVITY_LOADING = 'SET_CURRENT_ACTIVITY_LOADING';
export const SET_CONTENT_CARDS_LOADING = 'SET_CONTENT_CARDS_LOADING';
export const SET_CONTENT_LOADING = 'SET_CONTENT_LOADING';
export const SKIP_SESSION = 'SKIP_SESSION';

export async function fetchCurrentActivity() {
    const currentGoalName = getState().user.currentGoalLevel?.goalLevel.goal.name;

    store.dispatch({
        type: SET_CURRENT_ACTIVITY_LOADING,
        payload: true,
    });

    try {
        if (currentGoalName === 'default') {
            const { movies, sessions } = await Api.movies.random();

            store.dispatch({
                type: FETCH_CURRENT_ACTIVITY_SUCCESS,
                payload: {
                    movies,
                    sessions,
                },
            });
        } else {
            const sessions = await Api.customizedPlan.getPlans();
            store.dispatch({
                type: FETCH_CURRENT_ACTIVITY_SUCCESS,
                payload: { sessions },
            });
        }
    } finally {
        store.dispatch({
            type: SET_CURRENT_ACTIVITY_LOADING,
            payload: false,
        });
    }
}

export function fetchContentCards() {
    store.dispatch({
        type: SET_CONTENT_CARDS_LOADING,
        payload: true,
    });
    return Api.customizedPlan.getContentCards().then(
        response => {
            store.dispatch({
                type: FETCH_CONTENT_CARDS_SUCCESS,
                payload: response.data,
            });
            store.dispatch({
                type: SET_CONTENT_CARDS_LOADING,
                payload: false,
            });
            return Promise.resolve(response);
        },
        err => {
            store.dispatch({
                type: SET_CONTENT_CARDS_LOADING,
                payload: false,
            });
            return Promise.resolve(err);
        },
    );
}

export function setContentLoading(loading) {
    store.dispatch({
        type: SET_CONTENT_LOADING,
        payload: loading,
    });
}

export function resetCurrentActivities() {
    return {
        type: RESET_CURRENT_ACTIVITIES,
        payload: [],
    };
}

export const completeCustomizedPlanById = (id: string) => {
    return Api.customizedPlan.complete(id);
};

export const fetchUserActivitiesByDateRange = (startDate, endDate) => {
    return Api.userActivity.activitiesByDateRange
        .get(startDate.toISOString(), endDate.toISOString())
        .then(response => {
            store.dispatch({
                type: FETCH_ACTIVITY_BY_DATE_RANGE,
                payload: {
                    startDate,
                    endDate,
                    results: response.data,
                },
            });
        });
};

export const skipSession = async (sessionId: string) => {
    const {
        data: { session },
    } = await Api.session.skip(sessionId);
    store.dispatch({
        type: SKIP_SESSION,
        payload: session,
    });
};

export const createSessionByMovieId = async (movieId: string) => {
    const { sessionId } = await Api.session.createByMovieId(movieId);

    return sessionId;
};
