import { GAIN_INSIGHT_QUESTIONAIRE_SUCCESS } from 'state/actions/gain-insight.actions';

const initialState = {
    questionaire: [],
};

function gainInsightReducer(state = initialState, action): typeof initialState {
    switch (action.type) {
        case GAIN_INSIGHT_QUESTIONAIRE_SUCCESS:
            return {
                ...state,
                questionaire: action.payload.questions,
            };
        default:
            return state;
    }
}

export default gainInsightReducer;
