import {
    FETCH_GOALS_SUCCESS,
    FETCH_GOAL_LEVEL_INFO_SUCCESS,
    LEVELUP_OFFER_REJECT,
    CLEAR_LEVELUP_OFFER_REJECT,
    FETCH_PROGRAM_UPDATES_INFO_SUCCESS,
    FETCH_GOAL_PROGRESS_SUCCESS,
    GOAL_LEVEL_INFO_RESET,
    FETCH_GOAL_LEVEL_INFO_REQUEST,
    UPDATE_PROGRAM_OFFER_REJECT,
    CLEAR_UPDATE_PROGRAM_OFFER_REJECT,
    FETCH_NEXT_GOAL_SUCCESS,
} from '../actions/goals.actions';
import { SKIP_SESSION } from '../actions/user-activity.actions';

const initialState = {
    items: [],
    levelInfo: {
        isLoading: false,
        canLevelup: false,
        levelupPopup: false,
        isLastLevel: false,
        name: undefined,
        level: undefined,
        completed: 0,
        uncompleted: 0,
        canOfferPopup: false,
    },
    progress: {
        byLevel: [
            {
                level: 1,
                count: 0,
                percent: 0,
            },
            {
                level: 2,
                count: 0,
                percent: 0,
            },
            {
                level: 3,
                count: 0,
                percent: 0,
            },
        ],
        currentLevelProgress: { level: 1, count: 0, percent: 0 },
        totalPercent: 0,
    },
    programUpdateInfo: {
        programHasUpdates: false,
        canOfferPopup: false,
    },
    nextGoal: {
        name: undefined,
    },
};

function goalsReducer(state = initialState, action): typeof initialState {
    switch (action.type) {
        case FETCH_NEXT_GOAL_SUCCESS:
            return {
                ...state,
                nextGoal: action.goal,
            };
        case FETCH_PROGRAM_UPDATES_INFO_SUCCESS:
            const { programUpdateInfo } = action;
            const canOfferProgramUpdatePopup = !localStorage.getItem('program-update.offer.rejected');
            return {
                ...state,
                programUpdateInfo: { ...programUpdateInfo, canOfferPopup: canOfferProgramUpdatePopup },
            };
        case SKIP_SESSION:
            return {
                ...state,
                levelInfo: {
                    ...state.levelInfo,
                    uncompleted: state.levelInfo.uncompleted - 1,
                },
            };
        case UPDATE_PROGRAM_OFFER_REJECT:
            return {
                ...state,
                levelInfo: {
                    ...state.levelInfo,
                    canOfferPopup: false,
                },
            };
        case CLEAR_UPDATE_PROGRAM_OFFER_REJECT:
            return {
                ...state,
                levelInfo: {
                    ...state.levelInfo,
                    canOfferPopup: true,
                },
            };
        case FETCH_GOALS_SUCCESS:
            return {
                ...state,
                items: action.goals.filter(it => it.name !== 'default'),
            };
        case GOAL_LEVEL_INFO_RESET:
            return {
                ...state,
                levelInfo: {
                    ...initialState.levelInfo,
                },
            };
        case FETCH_GOAL_LEVEL_INFO_REQUEST:
            return {
                ...state,
                levelInfo: {
                    ...state.levelInfo,
                    isLoading: true,
                },
            };
        case FETCH_GOAL_LEVEL_INFO_SUCCESS:
            const { levelInfo } = action;
            const canOfferPopup = !localStorage.getItem(
                `levelup.offer.rejected.${levelInfo.name}-${levelInfo.level}`,
            );

            return {
                ...state,
                levelInfo: {
                    ...levelInfo,
                    canOfferPopup,
                    isLoading: false,
                },
            };
        case LEVELUP_OFFER_REJECT:
            return {
                ...state,
                levelInfo: {
                    ...state.levelInfo,
                    canOfferPopup: false,
                },
            };
        case CLEAR_LEVELUP_OFFER_REJECT:
            return {
                ...state,
                levelInfo: {
                    ...state.levelInfo,
                    canOfferPopup: true,
                },
            };
        case FETCH_GOAL_PROGRESS_SUCCESS:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    ...action.progress,
                },
            };
        default:
            return state;
    }
}

export default goalsReducer;
