import { Api } from 'services/api';
import { store } from 'state/store';

export const FETCH_SUBSCRIPTION_PLANS_SUCCESS = 'FETCH_SUBSCRIPTION_PLANS_SUCCESS';
export const FETCH_CURRENT_USER_PLAN_SUCCESS = 'FETCH_CURRENT_USER_PLAN_SUCCESS';
export const TRIAL_ACTIVATION_SUCCESS = 'TRIAL_ACTIVATION_SUCCESS';
export const FETCH_SUBSCRIPTION_INFO = 'FETCH_SUBSCRIPTION_INFO';

export const getSubscriptionPlans = async () => {
    const response = await Api.payments.plans.get();
    store.dispatch({
        type: FETCH_SUBSCRIPTION_PLANS_SUCCESS,
        payload: response,
    });
};

export const currentUserPlan = () => {
    return Api.payments.subscription.get().then(response => {
        store.dispatch({
            type: FETCH_CURRENT_USER_PLAN_SUCCESS,
            payload: response.data[0],
        });
    });
};

export const getSubscriptionInfo = async () => {
    const info = await Api.payments.subscription.info();

    store.dispatch({
        type: FETCH_SUBSCRIPTION_INFO,
        payload: info,
    });
};

export const activateTrial = async () => {
    const response = await Api.payments.trial.activate();
    store.dispatch({
        type: TRIAL_ACTIVATION_SUCCESS,
        payload: response.data,
    });
};
