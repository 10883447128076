import { FETCH_USER_INFO } from 'state/user/user.actions';
import { TRIAL_ACTIVATION_SUCCESS } from 'state/payments/payments.actions';
import { GoalLevelJson } from 'services/api/index.contracts';

type BillingInfo = {
    customerId: string;
    currentPlan: {
        currentPeriodEnd: number;
        id: string;
        status: 'trial' | 'active' | 'canceled';
    };
};

const initialState: {
    name?: string;
    stripeInfo?: BillingInfo;
    email?: string;
    avatar?: string;
    currentGoalLevel?: {
        goalLevel: GoalLevelJson;
        programUpdatedAt: string;
        lastLevel: boolean;
        contentUpdatedAt: string;
    };
    activity?: {
        todaysIPI: {
            value: number;
        };
        streak: {
            value: number;
        };
    };
    completedGoals?: string[];
} = {
    name: undefined,
    stripeInfo: null,
    email: undefined,
    avatar: undefined,
    currentGoalLevel: undefined,
    activity: undefined,
    completedGoals: [],
};

function userReducer(state = initialState, action): typeof initialState {
    switch (action.type) {
        case FETCH_USER_INFO:
            const { name, stripeInfo, email, avatar, currentGoalLevel, activity, completedGoals } =
                action.payload;

            return {
                ...state,
                stripeInfo,
                email,
                avatar,
                currentGoalLevel,
                name: name ? name.first_name : undefined,
                activity,
                completedGoals,
            };
        case TRIAL_ACTIVATION_SUCCESS:
            return {
                ...state,
                stripeInfo: action.payload,
            };
        default:
            return state;
    }
}

export default userReducer;
