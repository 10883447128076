import { Api } from 'services/api';
import { store } from 'state/store';

export const SUGGEST_NEW_TAG = 'SUGGEST_NEW_TAG';
export const LOAD_TAGS = 'LOAD_TAGS';

export const suggestNewTag = ({ name, type }) => {
    return Api.tags.suggest(name, type).then(() => {
        store.dispatch({
            type: SUGGEST_NEW_TAG,
            payload: {
                name,
                type,
            },
        });
    });
};

export const getTags = () => {
    return Api.tags.get().then(response => {
        store.dispatch({
            type: LOAD_TAGS,
            payload: response.data,
        });
    });
};
