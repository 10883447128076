import { Api } from 'services/api';
import { store } from 'state/store';

export const GET_CHECKIN_CATEGORIES = 'CHECKINS/GET_CHECKIN_CATEGORIES';
export const GET_MY_CHECKINS = 'CHECKINS/GET_MY_CHECKINS';
export const GET_MY_CHECKIN = 'CHECKINS/GET_MY_CHECKIN';

export const getCheckinCategories = () => {
    return Api.checkIn.getCategoriesList().then(response => {
        store.dispatch({
            type: GET_CHECKIN_CATEGORIES,
            payload: response.data,
        });

        return Promise.resolve(response);
    });
};

export const getMyCheckins = () => {
    return Api.checkIn.getMyCheckIns().then(response => {
        store.dispatch({
            type: GET_MY_CHECKINS,
            payload: response.data,
        });

        return Promise.resolve(response);
    });
};

export const getMyCheckIn = (id: string) => {
    return Api.checkIn.getMyCheckIn(id).then(response => {
        store.dispatch({
            type: GET_MY_CHECKIN,
            payload: response.data,
        });
    });
};

export const createCheckin = data => {
    return Api.checkIn.createCheckIn(data);
};
