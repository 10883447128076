import { Api } from 'services/api';
import { store } from 'state/store';

export const GAIN_INSIGHT_QUESTIONAIRE_SUCCESS = 'GAIN_INSIGHT_QUESTIONAIRE_SUCCESS';

export const getQuestionaire = () => {
    Api.gainInsight.questionaire.get().then(response => {
        store.dispatch({
            type: GAIN_INSIGHT_QUESTIONAIRE_SUCCESS,
            payload: response.data,
        });
    }, console.error);
};

export const sendAnswers = data => {
    return Api.gainInsight.answers.create(data).then(response => {
        return response.data;
    });
};
