import { GET_CHECKIN_CATEGORIES, GET_MY_CHECKINS, GET_MY_CHECKIN } from '../actions/checkin.actions';

const initialState = {
    categories: [],
    myCheckins: [],
    currentCheckin: null,
};

function checkinReducer(state = initialState, action): typeof initialState {
    switch (action.type) {
        case GET_CHECKIN_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
            };
        case GET_MY_CHECKINS:
            return {
                ...state,
                myCheckins: action.payload,
            };
        case GET_MY_CHECKIN:
            return {
                ...state,
                currentCheckin: action.payload,
            };
        default:
            return state;
    }
}

export default checkinReducer;
