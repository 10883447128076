import { Api } from 'services/api';
import { SessionJson } from 'services/api/index.contracts';
import { store } from 'state/store';

export const SEARCH_MOVIES = 'SESSION/SEARCH_MOVIES';
export const CLEAR_SEARCH_MOVIES = 'SESSION/CLEAR_SEARCH_MOVIES';
export const GET_GOAL_SESSIONS_REQUEST = 'SESSION/GET_GOAL_SESSIONS_REQUEST';
export const GET_GOAL_SESSIONS = 'SESSION/GET_GOAL_SESSIONS';
export const START_SESSION = 'SESSION/START_SESSION';
export const SET_SESSION_FOR_ACTION = 'SESSION/SET_SESSION_FOR_ACTION';
export const FETCH_SESSION_DETAILS = 'SESSION/FETCH_SESSION_DETAILS';

export const updateProgramSessions = () => {
    return Api.session.updateProgramSessions();
};

export const startSession = (sessionId: string) => {
    return Api.session.startSession(sessionId).then(
        response => {
            store.dispatch({
                type: START_SESSION,
                payload: response.data,
            });

            return Promise.resolve(response);
        },
        error => Promise.reject(error),
    );
};

export const createSession = (tmdbId: number, type: 'tv' | 'movie') => {
    return Api.session.create(tmdbId, type).then(response => response.data);
};

export const completeMovieMode = (sessionId, reactions?: any[]) => {
    return Api.session.completeMovie(sessionId, reactions).then(
        response => {
            return Promise.resolve(response.data);
        },
        error => Promise.reject(error),
    );
};

export const searchAllMovies = (term: string, type = 'movie') => {
    store.dispatch({
        type: CLEAR_SEARCH_MOVIES,
    });

    return Api.movies.tmdb.search.movie(term, type).then(response => {
        store.dispatch({
            type: SEARCH_MOVIES,
            payload: response && response.data,
        });
    });
};

export const getSessionsByGoal = goal => {
    store.dispatch({
        type: GET_GOAL_SESSIONS_REQUEST,
    });

    return Api.session.getSessionsByGoal(goal).then(
        response => {
            store.dispatch({
                type: GET_GOAL_SESSIONS,
                payload: response.data,
            });
        },
        err => Promise.reject(err),
    );
};

export const getOtherPastSessions = () => {
    return Api.customizedPlan.getPlans(true);
};

export const setSessionForAction = (session: SessionJson | null) => {
    store.dispatch({
        type: SET_SESSION_FOR_ACTION,
        payload: session,
    });
};

export const getSessionDetails = id => {
    return Api.session.getSessionDetails(id).then(response => {
        store.dispatch({
            type: FETCH_SESSION_DETAILS,
            payload: response.data,
        });
    });
};

export const addNote = (sessionId, text) => {
    return Api.session.sendNote(sessionId, { text });
};

export const saveReactionsDuringMovie = async (
    sessionId: string,
    currentTime: number,
    reactions: { type: 'sad' | 'happy'; time: number }[],
) => {
    return Api.session.reactionsDuringMovie(sessionId, { currentTime, reactions });
};
