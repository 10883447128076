import mixpanel, { Dict, Callback, RequestOptions } from 'mixpanel-browser';
import { singleton } from 'services/util-services/singleton';

export class MetricsTracker {
    static shared = singleton(() => new MetricsTracker());

    private initalized = false;

    init() {
        mixpanel.init('ef591418caa53fac9fdb55fcda1cdc67', {
            debug: process.env.NODE_ENV === 'development',
            ignore_dnt: true,
        });

        this.initalized = true;
    }

    track(
        eventName: string,
        properties?: Dict,
        optionsOrCallback?: RequestOptions | Callback,
        callback?: Callback,
    ) {
        if (this.initalized) {
            mixpanel.track(eventName, properties, optionsOrCallback, callback);
        }
    }
}
