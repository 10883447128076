import {
    ADD_NEW_NOTIFICATION,
    REMOVE_CURRENT_NOTIFICATION,
    GET_NOTIFICATIONS,
} from 'state/actions/notification.actions';

const initialState = {
    list: [],
    current: null,
};

function notificationsReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_NEW_NOTIFICATION:
            if (state.list.find(it => it._id === action.payload._id)) {
                return state;
            }

            return {
                ...state,
                list: [...state.list, action.payload],
                current: action.payload,
            };
        case GET_NOTIFICATIONS:
            return {
                ...state,
                list: action.payload.notifications,
            };
        case REMOVE_CURRENT_NOTIFICATION:
            return {
                ...state,
                current: null,
            };
        default:
            return state;
    }
}

export default notificationsReducer;
