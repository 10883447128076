import { Api } from 'services/api';
import {
    AUTHENTICATION_SUCCESS,
    AUTHENTICATION_REVOKE,
    AUTHENTICATION_ERROR,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    SIGNUP_ERROR,
    SIGNUP_SUCCESS,
    CLEAR_LOGIN_ERROR,
    CLEAR_SIGNUP_ERROR,
} from './actions';

const initialState = {
    identityConfirmed: false,
    login: {
        isFetching: false,
        failureReason: undefined,
    },
    signup: {
        isFetching: false,
        failureReason: undefined,
    }
};

export const authReducer = (state = initialState, action): typeof initialState => {
    switch (action.type) {
        case AUTHENTICATION_SUCCESS:
            return {
                ...state,
                identityConfirmed: true
            };
        case AUTHENTICATION_REVOKE:
            return {
                ...state,
                identityConfirmed: false
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: {
                    isFetching: false,
                    failureReason: undefined
                }
            };
        case SIGNUP_SUCCESS:
            return {
                ...state,
                signup: {
                    isFetching: false,
                    failureReason: undefined
                }
            };

        case LOGIN_ERROR:
            const loginError = action.payload;
            let loginFailureReason;
            if (loginError) {
                if (loginError?.isAxiosError) {
                    loginFailureReason = loginError.response?.data?.message;
                } else {
                    loginFailureReason = loginError.message;
                }
            }

            return {
                ...state,
                login: {
                    ...state.login,
                    isFetching: false,
                    failureReason: loginFailureReason,
                }
            };
        case SIGNUP_ERROR:
            const error = action.payload;
            let failureReason;
            if (error) {
                if (error?.isAxiosError) {
                    failureReason = error.response?.data?.message;
                } else {
                    failureReason = error.message;
                }
            }

            return {
                ...state,
                signup: {
                    isFetching: false,
                    failureReason,
                }
            };
        case CLEAR_LOGIN_ERROR:
            return {
                ...state,
                login: {
                    ...state.login,
                    failureReason: undefined,
                }
            };
        case CLEAR_SIGNUP_ERROR:
            return {
                ...state,
                signup: {
                    ...state.signup,
                    failureReason: undefined,
                }
            };
        case AUTHENTICATION_ERROR:
            Api.revokeAuthData();
            localStorage.removeItem('token');

            return state;
        default:
            return state;
    }
};
