import { combineReducers } from 'redux';
import userReducer from '../user/user.reducer';
import moviesReducer from './movies.reducer';
import goalsReducer from './goals.reducer';
import resetPasswordReducer from './reset-password.reducer';
import userActivityReducer from './user-activity.reducer';
import gainInsightReducer from 'state/reducers/gain-insight.reducer';
import cahmReducer from 'state/reducers/cahm.reducer';
import ipiReducer from './ipi.reducer';
import paymentsReducer from '../payments/payments.reducer';
import onboardingReducer from './onboarding.reducer';
import notificationsReducer from './notifications.reducer';
import sessionReducer from './session.reducer';
import { authReducer } from 'state/auth/reducer';
import { mailingListReducer } from 'state/mailing-list/reducer';
import checkinsReducer from './checkin.reducer';
import dashboardReducer from './dashboard.reducer';
import tagsReducer from '../tags/reducer';

const appReducer = combineReducers({
    user: userReducer,
    movies: moviesReducer,
    goals: goalsReducer,
    resetPassword: resetPasswordReducer,
    userActivity: userActivityReducer,
    gainInsight: gainInsightReducer,
    cahm: cahmReducer,
    ipi: ipiReducer,
    payments: paymentsReducer,
    onboarding: onboardingReducer,
    notifications: notificationsReducer,
    sessions: sessionReducer,
    auth: authReducer,
    mailingList: mailingListReducer,
    checkins: checkinsReducer,
    dashboard: dashboardReducer,
    tags: tagsReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
