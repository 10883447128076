import { SEND_MAIL_REQUEST, RESET_PASSWORD_BLOCKING_PERIOD_CLEARED } from '../actions/reset-password.actions';

const initialState = {
    blockingPeriod: localStorage.getItem('resetPasswordBlockingPeriod'),
};

function resetPasswordReducer(state = initialState, action) {
    switch (action.type) {
        case SEND_MAIL_REQUEST:
            return { ...state, blockingPeriod: action.payload };
        case RESET_PASSWORD_BLOCKING_PERIOD_CLEARED:
            return { ...state, blockingPeriod: null };
        default:
            return state;
    }
}

export default resetPasswordReducer;
