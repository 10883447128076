import axios, { AxiosInstance } from 'axios';
import config from '../../config';
import { singleton } from 'services/util-services/singleton';
import { store } from 'state/store';
import {
    type MovieJson,
    type LoginData,
    type RegisterData,
    type SubscriptionPlanJson,
    type SessionJson,
    type SubscriptionInfoJson,
} from './index.contracts';
import type { GoogleSignInResponse } from 'services/google/init';

export const baseURL = config.baseUrl;

class ApiImplementation {
    static shared = singleton(() => new ApiImplementation());

    baseUrl = config.baseUrl;
    private readonly transport: AxiosInstance;

    constructor() {
        this.transport = axios.create({
            baseURL: this.baseUrl,
        });

        this.transport.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                if (
                    (error.isAxiosError &&
                        (error?.response?.status === 401 || error?.response?.status === 403)) ||
                    error.response?.data?.type === 'TOKEN_EXPIRED'
                ) {
                    if (!error.config.url.includes('/user/password/change')) {
                        store.dispatch({
                            type: 'AUTHENTICATION_ERROR',
                            payload: error,
                        });
                    }
                }

                return Promise.reject(error.response?.data);
            },
        );

        const token = localStorage.getItem('token');

        if (token) {
            this.setAuthData(token);
        }
    }

    readonly auth = {
        authenticate: (password: string) => this.transport.post('/auth/authenticate', { password }),
        login: (data: LoginData) => this.transport.post('/auth/signin', data),
        signup: (data: RegisterData) => this.transport.post('/auth/register', data),
        facebook: {
            login: (accessToken: string) => this.transport.post('/auth/facebook', { accessToken }),
        },
        google: {
            login: (identity: GoogleSignInResponse) => this.transport.post('/auth/google', { identity }),
        },
    };

    cahm = {
        questionaire: {
            get: () => this.transport.get('/cahm'),
            answers: {
                create: (data: { sessionId: string; questions: any[] }) => this.transport.post('/cahm', data),
                get: {
                    byId: id => this.transport.get(`/cahm/answers/${id}`),
                },
            },
        },
    };

    reflectionLog = {
        result: {
            get: (id: string) => this.transport.get(`/reflection-log/${id}`),
        },
    };

    emotions = {
        get: () => this.transport.get('/practices'),
    };

    gainInsight = {
        questionaire: {
            get: () => this.transport.get('/gain-insight'),
        },
        answers: {
            create: data => this.transport.post('/gain-insight', data),
            get: {
                byMovie: movie =>
                    this.transport.get('/gain-insight/answers', {
                        params: { movie },
                    }),
                byId: id => this.transport.get(`/gain-insight/answers/${id}`),
            },
        },
    };

    goals = {
        get: () => this.transport.get('/goals'),
        getProgress: () => this.transport.get('/goals/progress'),
        levels: {
            get: () => this.transport.get('/goals/goal-levels'),
            info: () => this.transport.get('/goals/level-info'),
            levelup: () => this.transport.post('/goals/levelup'),
        },
        setDefault: data => this.transport.put('/goals/quiz', data),
        update: id => this.transport.put('/goals/update', { goalId: id }),
        complete: () => this.transport.put('/goals/complete'),
        getNextGoal: () => this.transport.get('/goals/next-goal'),
        checkForProgramUpdates: () => this.transport.get('/goals/check-for-updates'),
    };

    ipi = {
        stats: {
            get: () => this.transport.get('/ipi/stats'),
            gainInsight: {
                get: () => this.transport.get('/ipi/stats/gain-insight'),
                getByEmotionName: name => this.transport.get(`/ipi/stats/emotions/${name}`),
            },
            count: () => this.transport.get('/ipi/count-stats'),
        },
        tasks: {
            complete: name => this.transport.post(`/ipi/tasks/${name}`),
        },
    };

    mailingList = {
        subscribe: (email: string) => this.transport.post('/mailing-list/subscribe', { email }),
        settings: {
            get: () => this.transport.get('/mailing-list/settings'),
            update: (notifications: any[]) =>
                this.transport.put('/mailing-list/settings/preferences', {
                    notifications,
                }),
        },
    };

    moodchecks = {
        create: (score, sessionId) => this.transport.post('/moods/', { score, sessionId }),
        getAnswerById: id => this.transport.get(`/moods/${id}`),
        getAnswerByMovieId: id => this.transport.get('/moods/by', { params: { movie: id } }),
    };

    movies = {
        byId: {
            get: id => this.transport.get(`/movies/byid/${id}`),
        },
        tmdb: {
            search: {
                movie: (term, type) => {
                    return this.transport.get('/movies/search', {
                        params: {
                            term,
                            type,
                        },
                    });
                },
            },
        },
        getFavoritesAsSessions: () => {
            const route = '/movies-list/favorites-as-sessions';
            return this.transport.get(route);
        },
        getMovieList: (type: 'favorite' | 'hidden', expand = false) => {
            const route = expand ? '/movies-list/populated' : '/movies-list';

            return this.transport.get(`${route}?type=${type}`);
        },
        addToFavorites: movieId =>
            this.transport.post('/movies-list', {
                type: 'favorite',
                movie: movieId,
            }),
        deleteFromFavorites: movieId => this.transport.delete(`/movies-list/${movieId}/favorite`),
        deleteFromHidden: (movieId: string) => this.transport.delete(`/movies-list/${movieId}/hidden`),
        hideMovie: movieId =>
            this.transport.post('/movies-list', {
                type: 'hidden',
                movie: movieId,
            }),
        checkMovieExists: (id, type) => this.transport.get(`/movies-list/${id}?type=${type}`),
        random: () =>
            this.transport
                .get<{
                    movies: MovieJson[];
                    sessions: SessionJson[];
                }>(`/movies/random`)
                .then(r => r.data),
    };

    notifications = {
        get: () => this.transport.get('/notifications'),
        readAll: () => this.transport.put('/notifications'),
    };

    payments = {
        createCheckoutSession: (lookupKey: string) =>
            this.transport.post('/payments/create-checkout-session', {
                lookup_key: lookupKey,
            }),
        createPortalSession: () => this.transport.post('/payments/create-portal-session'),
        subscription: {
            info: () =>
                this.transport.get<SubscriptionInfoJson>('/payments/subscription-info').then(it => it.data),
            get: () => this.transport.get('/payments/subscription'),
        },
        plans: {
            get: () =>
                this.transport.get<SubscriptionPlanJson>('/payments/plans').then(response => {
                    return response.data;
                }),
        },
        trial: {
            activate: () => this.transport.post('/payments/trial/activate'),
        },
    };

    session = {
        createByMovieId: (movieId: string) =>
            this.transport
                .post<{ sessionId: string }>(`/customized-plans/create/by-movie`, {
                    movieId,
                })
                .then(it => it.data),
        create: (tmdbId: number, type: 'tv' | 'movie') =>
            this.transport.post('/customized-plans/create', {
                tmdbId,
                type,
            }),
        startSession: (movieId: string) => this.transport.post(`/customized-plans/start/session/${movieId}`),
        completeMovie: (sessionId: string, reactions: any[]) =>
            this.transport.post(`/customized-plans/complete/session/${sessionId}`, {
                reactions,
            }),
        getByMovieId: movieId => this.transport.get(`/customized-plans/by-movie/${movieId}`),
        getByMovieIds: ids => axios.all(ids.map(id => this.session.getByMovieId(id))),
        getCurrentGoalSessions: () => this.transport.get('/customized-plans/current-goal'),
        getSessionsByGoal: goal => this.transport.get(`/customized-plans/by-goal/${goal}`),
        getSessionDetails: (id: string) => this.transport.get(`/customized-plans/details/${id}`),
        sendNote: (id: string, data: { text: string }) =>
            this.transport.post(`/customized-plans/notes/${id}`, data),
        updateProgramSessions: () => this.transport.post('/customized-plans/update-program-sessions'),
        reactionsDuringMovie: (sessionId: string, data: any) => {
            return fetch(`${this.baseUrl}customized-plans/reactions-during-movie/${sessionId}`, {
                method: 'POST',
                keepalive: true,
                headers: {
                    Authorization: this.transport.defaults.headers.common['Authorization'],
                },
                body: JSON.stringify(data),
            });
        },
        cards: {
            get: () => this.transport.get('/reflection-log'),
        },
        moodcheck: {
            complete: id => this.transport.post(`/reflection-log/complete/moodcheck/${id}`),
        },
        movie: {
            complete: id => this.transport.post(`/reflection-log/complete/movie/${id}`),
        },
        practice: {
            complete: id => this.transport.post(`/reflection-log/complete/practice/${id}`),
            skip: (movieId, moodcheckId) =>
                this.transport.put(`/reflection-log/skip/practice/${movieId}/${moodcheckId}`),
        },
        skip: (sessionId: string) => this.transport.post(`/customized-plans/skip/session/${sessionId}`),
    };

    checkIn = {
        getCategoriesList: () => this.transport.get('/check-ins/list'),
        getMyCheckIns: () => this.transport.get('/check-ins'),
        getMyCheckIn: (id: string) => this.transport.get(`/check-ins/${id}`),
        deleteCheckIn: (id: string) => this.transport.get(`/check-ins/${id}`),
        createCheckIn: data => this.transport.post('/check-ins', data),
    };

    tags = {
        suggest: (name: string, type: string) => this.transport.post('/tags/suggest', { name, type }),
        get: () => this.transport.get('/tags'),
    };

    users = {
        me: () => this.transport.get('/user/me'),
        onboarding: {
            get: () => this.transport.get('/user/onboarding'),
            update: name => this.transport.post(`/user/onboarding/${name}`),
        },
        info: {
            update: (data: { name?: string; email?: string }) => this.transport.put('/user/info', data),
        },
        email: {
            confirm: (code: string) => this.transport.get(`/user/email/confirm/${code}`),
        },
        avatar: {
            update: newAvatar => this.transport.put('/user/avatar', newAvatar),
        },
        password: {
            reset: (email: string) => this.transport.post('/user/reset-password', { email }),
            confirm: (code: string, password: string) =>
                this.transport.post('/user/reset-password/confirm', {
                    code,
                    password,
                }),
            change: (oldPassword: string, newPassword: string) => {
                return this.transport.post('/user/password/change', {
                    oldPassword,
                    newPassword,
                });
            },
        },
    };

    feedback = {
        send: data => this.transport.post('/feedback', data),
    };

    userActivity = {
        customizedPlan: {
            get: () => this.transport.get('/customized-plans'),
        },
        weeklyActivity: {
            get: () => this.transport.get('/user-activity/week'),
        },
        activitiesByDateRange: {
            get: (startDate: string, endDate: string) => {
                return this.transport.get(
                    `/user-activity/by-range?startDate=${startDate}&endDate=${endDate}`,
                );
            },
        },
    };

    customizedPlan = {
        getPlans: (createdBySearchOnly = false) =>
            this.transport
                .get<SessionJson[]>(
                    `/customized-plans${createdBySearchOnly ? '?createdBySearchOnly=true' : ''}`,
                )
                .then(r => r.data),
        getContentCards: () => this.transport.get('/customized-plans/content-cards'),
        getDetails: (id: string) => this.transport.get(`/customized-plans/details/${id}`),
        complete: id => this.transport.post(`/customized-plans/complete/${id}`),
    };

    setAuthData(token: string) {
        this.transport.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    revokeAuthData() {
        delete this.transport.defaults.headers.common['Authorization'];
    }
    polls = {
        experience: {
            create: (data: { question: string; answer: boolean }[]) =>
                this.transport.post('/polls/experience', { data }),
            availability: () => this.transport.get<{ available: boolean }>('/polls/availability'),
        },
    };
}

const Api = ApiImplementation.shared();

if (process.env.NODE_ENV === 'development') {
    window.api = Api;
}

export { Api };
