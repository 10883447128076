import { CAHM_QUESTIONAIRE_SUCCESS } from 'state/actions/cahm.actions';

const initialState = {
    questionaire: [],
};

function cahmReducer(state = initialState, action): typeof initialState {
    switch (action.type) {
        case CAHM_QUESTIONAIRE_SUCCESS:
            return {
                ...state,
                questionaire: action.payload.questions,
            };
        default:
            return state;
    }
}

export default cahmReducer;
