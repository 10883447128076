import { Suspense, useMemo, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useGlobalStyleContext } from './global-styles-context';
import EmailConfirm from 'app/pages/email-confirm/EmailConfirm';
import { useScrollToTop } from 'app/shared/scroll-to-top/ScrollToTop';
import PageLoading from 'app/shared/page-loading/PageLoading';
import classNames from 'classnames';
import { userIsAuthenticated } from 'state/auth/actions';
import * as Sentry from '@sentry/react';

const MainLayout = lazy(() => import('app/layouts/main-layout/MainLayout'));
const Landing = lazy(() => import('./layouts/landing/MainRouters'));
const Auth = lazy(() => import('./pages/auth/Auth'));

const App = () => {
    const { className } = useGlobalStyleContext();
    const AppClasses = useMemo(() => classNames('App', className), [className]);
    const authenticated = userIsAuthenticated();

    useScrollToTop();

    return (
        <div className={AppClasses}>
            <Suspense fallback={<PageLoading />}>
                <Switch>
                    <Route path="/auth">
                        <Auth />
                    </Route>

                    <Route path="/email-confirm/:code" exact>
                        <EmailConfirm />
                    </Route>
                    <Route path="/">{authenticated ? <MainLayout /> : <Landing />}</Route>
                </Switch>
            </Suspense>
            <ToastContainer autoClose={2000} hideProgressBar={true} closeButton={false} />
        </div>
    );
};

export default Sentry.withProfiler(App);
