import {
    FETCH_IPI_USER_STATS_SUCCESS,
    FETCH_IPI_GAIN_INSIGHT_STATS_SUCCESS,
    FETCH_IPI_GAIN_INSIGHTS_BY_EMOTION,
    ADD_IPI_FETCHED_GAIN_INSIGHTS_BY_EMOTIONS, FETCH_IPI_STATS_COUNT_SUCCESS,
} from 'state/actions/ipi.actions';

const initialState = {
    tasks: {},
    stats: {
        userStats: null,
        gainInsight: {
            list: [],
            emotions: {},
            thinkingTraps: [],
        },
        gainInsightsByEmotions: {},
        statsCount: {
            currentIpiPoints: 0,
            userIPICountByCategory: []
        }
    },
};

function ipiReducer(state = initialState, action): typeof initialState {
    switch (action.type) {
        case FETCH_IPI_USER_STATS_SUCCESS:
            return {
                ...state,
                stats: {
                    ...state.stats,
                    userStats: action.payload,
                },
            };
        case FETCH_IPI_STATS_COUNT_SUCCESS:
            return {
                ...state,
                stats: {
                    ...state.stats,
                    statsCount: action.payload,
                }
            };
        case FETCH_IPI_GAIN_INSIGHT_STATS_SUCCESS:
            return {
                ...state,
                stats: {
                    ...state.stats,
                    gainInsight: action.payload,
                },
            };
        case FETCH_IPI_GAIN_INSIGHTS_BY_EMOTION:
            return {
                ...state,
                stats: {
                    ...state.stats,
                    gainInsight: {
                        ...state.stats.gainInsight,
                        list: action.payload,
                    },
                },
            };
        case ADD_IPI_FETCHED_GAIN_INSIGHTS_BY_EMOTIONS:
            const { emotionName } = action.payload;
            return {
                ...state,
                stats: {
                    ...state.stats,
                    gainInsightsByEmotions: {
                        ...state.stats.gainInsightsByEmotions,
                        [emotionName]: action.payload.list,
                    },
                },
            };
        default:
            return state;
    }
}

export default ipiReducer;
