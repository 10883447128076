import { Api } from 'services/api';
import { store } from 'state/store';

export const SEND_MAIL_REQUEST = 'SEND_MAIL_REQUEST';
export const RESET_PASSWORD_BLOCKING_PERIOD_CLEARED = 'RESET_PASSWORD_BLOCKING_PERIOD_CLEARED';

export const sendPasswordReset = (email: string) => {
    const blockingPeriod = Date.now() + 30000; // 30 seconds

    localStorage.setItem('resetPasswordBlockingPeriod', blockingPeriod.toString());

    store.dispatch({
        type: SEND_MAIL_REQUEST,
        payload: blockingPeriod
    });

    return Api.users.password.reset(email);
};

export const clearResetPasswordBlockingPeriod = () => {
    localStorage.removeItem('resetPasswordBlockingPeriod');

    store.dispatch({
        type: RESET_PASSWORD_BLOCKING_PERIOD_CLEARED,
    });
};

export const sendNewPassword = (code: string, password: string) => {
    return Api.users.password.confirm(code, password);
};
