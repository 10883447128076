import type { GoalJson } from 'services/api/index.contracts';
import { store } from 'state/store';

export const SET_DASHBOARD_STATE = 'SET_DASHBOARD_STATE';

export const setDashboardStateInfo = (userGoalName: GoalJson['name'], contentCardsType: string) => {
    store.dispatch({
        type: SET_DASHBOARD_STATE,
        payload: { userGoalName, contentCardsType },
    });
};
