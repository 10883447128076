import { GoalJson } from 'services/api/index.contracts';
import { SET_DASHBOARD_STATE } from 'state/actions/dashboard.actions';

type DashboardState = {
    contentCardsType: 'guide' | 'lesson' | undefined;
    userGoalName: GoalJson['name'] | undefined;
};

const initialState: DashboardState = {
    contentCardsType: null,
    userGoalName: undefined,
};

export default function dashboardReducer(state = initialState, action): DashboardState {
    switch (action.type) {
        case SET_DASHBOARD_STATE:
            return { ...state, ...action.payload };

        default:
            return state;
    }
}
