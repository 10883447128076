import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import App from './app/App';
import './styles/index.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import { store } from 'state/store';
import { GlobalStyleContextProvider } from 'app/global-styles-context';
import { materialTheme } from './config/theme';
import { MetricsTracker } from 'services/mixpanel';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { StrictMode } from 'react';

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        FB: any;
        google: {
            accounts: {
                id: {
                    initialize(options: Record<string, any>): void;
                    renderButton(element: HTMLElement, options: Record<string, any>): void;
                };
            };
        };
        api: any;
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
        store: any;
    }
}

MetricsTracker.shared().init();
Sentry.init({
    dsn: 'https://38f1b108f6c74ea2aadb18819b28864e@o4504286910021632.ingest.sentry.io/4504286914150401',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});

render(
    <StrictMode>
        {/* @ts-expect-error */}
        <BrowserRouter>
            <Provider store={store}>
                <MuiThemeProvider theme={materialTheme}>
                    <GlobalStyleContextProvider>
                        <App />
                    </GlobalStyleContextProvider>
                </MuiThemeProvider>
            </Provider>
        </BrowserRouter>
    </StrictMode>,
    document.getElementById('root'),
);
