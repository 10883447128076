import { createContext, useContext, useState } from 'react';

const GlobalStyleContext = createContext(undefined);

export const useGlobalStyleContext = () => useContext(GlobalStyleContext);

export const GlobalStyleContextProvider = ({ children }) => {
    const [className, setClassName] = useState('');

    return (
        <GlobalStyleContext.Provider value={{ className, setClassName }}>
            {children}
        </GlobalStyleContext.Provider>
    );
};
