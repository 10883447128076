export function makeStorage() {
    const storage: { [key: string]: any } = {};

    return {
        load: (key: string) => storage[key],
        store: (key: string, value: any) => {
            storage[key] = value;
        },
    };
}

export function singleton<Result, Parameters extends any[]>(
    createInstance: (...args: Parameters) => Result,
): (...args: Parameters) => Result {
    let storage: ReturnType<typeof makeStorage> | undefined;

    return (...args) => {
        if (!storage) {
            storage = makeStorage();
        }

        const key = JSON.stringify(args);

        let instance = storage.load(key);
        if (!instance) {
            instance = createInstance(...args);
            storage.store(key, instance);
        }

        return instance;
    };
}
